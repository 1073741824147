<template>
  <v-app>    
    <v-app-bar      
      app
      clipped-left            
    >
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->      
      <v-toolbar-title>
        <router-link to="/" class="text-h4" style="text-decoration:none; color:#0c9; font-weight: 900;">Nullye</router-link>
      </v-toolbar-title>      
    </v-app-bar>
    <v-main app>      
      <router-view/>      
    </v-main>
    <v-footer absolute app class="d-flex flex-column align-center justify-center py-3">
      <div class="text-center w-100 my-2 text-body-1">
        이 사이트의 모든 저작물은 <strong>웹접근성</strong> 지침을 준수하여 제작되었습니다.
      </div>      
      <div class="text-center w-100 my-1 text-body-1">
        <v-btn title flat class="mx-4 d-inline-flex" href="mailto:nullye@nullye.com" target="_blank">제휴 및 광고 문의</v-btn>
        <v-btn title flat class="mx-4 d-inline-flex" href="mailto:nullye@nullye.com" target="_blank">후원 문의</v-btn>
        <!-- <a href="nullye@nullye.com" class="mx-4 d-inline-flex">제휴 및 광고 문의</a>
        <a href="nullye@nullye.com" class="mx-4 d-inline-flex">후원 문의</a> -->
      </div>
      <div class="text-center w-100 my-1 text-body-2">
        Copyright ⓒ {{new Date().getFullYear()}} <strong>Nullye</strong> All rights reserved.
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { useTheme } from 'vuetify'

export default {
  name: 'App',
  created: function () {    
    document.title = '널리 - 모두를 위한 도구';
    // console.log('api key : '+thi s.apiKey);
    if (window.matchMedia) {
      const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const theme = useTheme()  
      
      //브라우저 설정 변경 시 다크 모드 업데이트
      darkModeMediaQuery.addEventListener('change', function(e){
        theme.global.name.value = e.matches ? 'dark' : 'light';     
      });
    }
  },
  data: () => ({
    //
    drawer:false,
  }),
}
</script>
