import { createWebHistory, createRouter } from "vue-router";
// import Main from "@/views/mainPage.vue";
// import Metronome from "../tools/metronome/nullyeMetronome.vue";

const routes = [    
  {
    path: "/",
    name: "MainPage",
    component: () => import('@/views/mainPage.vue'),
    meta: {
      title:'널리 - 모두를 위한 도구',
    }
  },
  {
    path: "/metronome",
    name: "Metronome",
    component: () => import('@/tools/metronome/nullyeMetronome.vue'),
    meta: {
      title:'널리 - 메트로놈',
    }
  },
];

const router = createRouter({
  history: createWebHistory(),  
  routes,  
});


router.afterEach((to) => {
  const title = to.meta.title === undefined ? 'Nullye' : to.meta.title;
  document.title = title;
});

export default router;